import { hasJsonWebTokenFormat } from "@lib/hasJsonWebTokenFormat";
import React from "react";
import { type OpenAssistantMessagePayload } from "src/app";
import { type DuffelAssistantProps } from "../components/DuffelAssistant";

type MessagePayloadFromIframe = CloseAssistantMessagePayload;

export interface CloseAssistantMessagePayload {
  type: "duffel-assistant-close";
}

const CUSTOM_ELEMENT_TAG = "duffel-assistant" as const;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      [CUSTOM_ELEMENT_TAG]: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }

  interface Window {
    // For merchants to call to open the component
    openDuffelAssistant: (props: DuffelAssistantProps) => void;

    // For our analytics to know if mixpanel has been initialised
    DUFFEL_ASSISTANT_ANALYTICS_INITIALISED?: boolean;
  }
}

export default class DuffelAssistantCustomElement extends HTMLElement {
  shadowRootInternal!: ShadowRoot;
  iFrameInternal!: HTMLIFrameElement;

  set shadowRoot(value: ShadowRoot) {
    this.shadowRootInternal = value;
  }

  get shadowRoot() {
    if (!this.shadowRootInternal) {
      throw new Error(
        "It was not possible to open the Duffel Assistant because the shadow root was not found",
      );
    }
    return this.shadowRootInternal;
  }

  set iframe(value: HTMLIFrameElement) {
    this.iFrameInternal = value;
  }

  get iframe() {
    if (!this.iFrameInternal) {
      throw new Error(
        "It was not possible to open Duffel Assistant because the iframe was not found",
      );
    }
    return this.iFrameInternal;
  }

  get iframeContentWindow() {
    if (!this.iframe.contentWindow) {
      throw new Error(
        "It was not possible to open the Duffel Assistant because the iframe content window was not found",
      );
    }
    return this.iframe.contentWindow;
  }

  connectedCallback() {
    this.shadowRoot = this.attachShadow({ mode: "closed" });

    const iFrameStyles = this.loadStyles();
    this.shadowRoot.appendChild(iFrameStyles);

    this.iframe = this.createIframe();
    this.shadowRoot.appendChild(this.iframe);

    this.setupMessageEventListener();
  }

  public makeIframeVisible(properties: DuffelAssistantProps) {
    const payload: OpenAssistantMessagePayload = {
      type: "duffel-assistant-open",
      properties,
    };
    this.iframeContentWindow.postMessage(payload, "*");
    this.iframe.classList.add("duffel-assistant-iframe--visible");
  }

  private loadStyles(): HTMLLinkElement {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = `${process.env.CDN_URL}/custom-element.css`;
    return link;
  }

  private createIframe(): HTMLIFrameElement {
    const iframe = document.createElement("iframe");
    iframe.id = "duffel-assistant-iframe";
    iframe.src = `${process.env.CDN_URL}/iframe.html`;

    return iframe;
  }

  private closeIframe() {
    this.iframe.classList.remove("duffel-assistant-iframe--visible");
  }

  private setupMessageEventListener() {
    window.addEventListener(
      "message",
      (event: MessageEvent<MessagePayloadFromIframe>) => {
        if (event.data.type === "duffel-assistant-close") {
          this.closeIframe();
        }
      },
    );
  }
}

window.customElements.get(CUSTOM_ELEMENT_TAG) ||
  window.customElements.define(
    CUSTOM_ELEMENT_TAG,
    DuffelAssistantCustomElement,
  );

window.addEventListener("load", () => {
  // Make open function available on the window
  window.openDuffelAssistant = (props: DuffelAssistantProps) => {
    if (!hasJsonWebTokenFormat(props.clientKey)) {
      throw new Error(
        `The prop 'clientKey' is required when calling 'openDuffelAssistant' and must be a valid JWT. Received: ${props.clientKey}`,
      );
    }

    const element =
      document.querySelector<DuffelAssistantCustomElement>(CUSTOM_ELEMENT_TAG);

    if (!element) {
      throw new Error(
        `Could not find ${CUSTOM_ELEMENT_TAG} element in the DOM. Maybe you need to call 'openDuffelAssistant' after 'window.onload'?`,
      );
    }

    element.makeIframeVisible(props);
  };
});
